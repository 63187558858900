import React from 'react';
import { ListGroupItem, InputGroup, InputGroupText, InputGroupAddon, ButtonGroup, Button } from 'reactstrap';

import ImagePowerOff from '../../../images/powerOff.png'
import ImagePowerOn from '../../../images/powerOn.png'

const HostItem = (props) => {

    const onPowerOn = (e) => {
        e.preventDefault();
        props.switchPower(props.nameComputer, 'on');
    };

    const onPowerOff = (e) => {
        e.preventDefault();
        props.switchPower(props.nameComputer, 'off');
    };

    return (
        <ListGroupItem className='justify-content-between'>
            <InputGroup>
                <InputGroupAddon addonType='prepend'>
                    <InputGroupText style={{ width: 200, wordWrap: 'normal' }}>{props.nameComputer}</InputGroupText>
                    <InputGroupText>
                        <img src={props.isRunning ? ImagePowerOn : ImagePowerOff} alt='Power'/>
                    </InputGroupText>
                </InputGroupAddon>
                <InputGroupAddon addonType='append'>
                    <ButtonGroup>
                        <Button color='success' disabled={props.isRunning} onClick={onPowerOn}>Включить</Button>
                        <Button color='danger' disabled={!props.isRunning && props.canShutdown} onClick={onPowerOff}>Выключить</Button>
                    </ButtonGroup>
                </InputGroupAddon>
            </InputGroup>
        </ListGroupItem>
    );
};


export default HostItem;