import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import 'toastr/build/toastr.min.css';
import { rootReducer } from './reducers/rootReducer';

export const configureStore = (preloadedState) => {
    //  постоянные middleware
    const middlewares = [thunk];

    //  если в режиме разработки, то добавим логгер
    if (process.env.NODE_ENV === 'development') {
        middlewares.push(logger);
    }

    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer /*, other_enchancers*/];
    const composedEnhancers = composeWithDevTools(...enhancers);

    const store = createStore(rootReducer, preloadedState, composedEnhancers);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./reducers/rootReducer', () => store.replaceReducer(rootReducer));
    }

    return store;
};