import React, { useState, useEffect } from 'react';
import { Container, InputGroup, InputGroupText, InputGroupAddon, Button, ListGroupItem, Input, Spinner } from 'reactstrap';
import { HubConnectionBuilder } from '@microsoft/signalr';

import HostItem from './HostItem';

const HostComponent = () => {
    const [connection, setConnection] = useState(null);
    const [hostList, setHostList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [infoMessage, setInfoMessage] = useState('');

    useEffect(() => {
            const newConnection = new HubConnectionBuilder()
                .withUrl('/hubs/hosts')
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
        },
        []);

    useEffect(() => {
            if (connection) {
                connection.start()
                    .then(result => {

                        connection.on('ReceiveHosts',
                            list => {
                                setLoading(false);
                                setHostList(list);
                            });

                        connection.on('ReceiveInfo',
                            message => {
                                setInfoMessage(message);
                            });
                    })
                    .catch(e => console.log('Connection failed: ', e));
            }
            return () => {
                if (connection)
                    connection.stop();
            };
        },
        [connection]);

    const switchPower = async (name, type) => {
        try {
            connection.invoke('SendSwitchPower', name, type).catch(err => console.log(err));
        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    };

    return (
        <Container style={{ margin: '0px 0px 20px 0px' }}>
            <ListGroupItem className='justify-content-between'>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                        <InputGroupText>
                            <strong>Инфо</strong>
                        </InputGroupText>
                        <Button outline color='info' onClick={() => setInfoMessage('')}>Скрыть</Button>
                    </InputGroupAddon>
                    <Input disabled value={infoMessage}/>
                </InputGroup>
            </ListGroupItem>
            {
                loading
                    ? <div>
                          <Spinner color='secondary'/>
                          <p>
                              <em> Loading...</em>
                          </p>
                      </div>
                    : hostList.map(
                        host =>
                        <HostItem
                            key={host.presentationHostName}
                            nameComputer={host.presentationHostName}
                            isRunning={host.isRunning}
                            canShutdown={host.canShutdown}
                            switchPower={switchPower}/>
                    )
            }
        </Container>
    );
};

export default HostComponent;