import React from 'react';
import { List, Jumbotron } from 'reactstrap';

import Message from './Message';

const ChatWindow = (props) => {

    const chat = props.chat.map(
        m =>
        <Message
            key={Date.now() * Math.random()}
            dateTime={m.dateTime}
            user={m.user}
            text={m.text}/>
    );

    const styleJumbotron = {
        height: '234px',
        maxHeight: '234px',
        overflowX: 'hidden',
        overflowY: 'scroll',
        padding: 0,
        margin: 0,
        width: '100%'
    };

    return (
        <Jumbotron id='jumbo' style={styleJumbotron}>
            <List type='unstyled' style={{ padding: '5px' }}>
                {chat}
            </List>
        </Jumbotron>
    );
};


export default ChatWindow;