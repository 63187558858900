import * as Types from '../actionTypes'

const initialState = {
    IsMobileDevice: false,
    AssemblyVersion: '1.0.0.0'
};

export const reducerApp = (state = initialState, action) => {
    switch (action.type) {
        case Types.SET_PLATFORM:
            return {
                ...state,
                IsMobileDevice: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
            };
        case Types.SET_ASSEMBLY_VERSION:
            return {
                ...state,
                AssemblyVersion: action.payload
            };
        default:
            return state;
    }
};