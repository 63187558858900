import toastr from 'toastr'

export default class Notification {

    static GetTimeString() {
        const date = new Date();
        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
    }

    static MesOk(text, title, hide = true) {
        const message = `${this.GetTimeString()} | ${text}`;
        toastr.success(message,
            title,
            {
                'timeOut': hide ? 3000 : -1,
                'closeButton': true,
                'progressBar': true
            });
    }

    static MesEr(text, title, hide = false) {
        const message = `${this.GetTimeString()} | ${text}`;
        toastr.error(message,
            title,
            {
                'timeOut': hide ? 3000 : -1,
                'closeButton': true,
                'progressBar': true
            });
    }

}