//Обертка для лога в приложении
function Log(sender, message) {
    if (sender === null || sender === undefined)
        throw new Error('LogTools: sender not set');

    console.log(sender + ': ' + message);
}

//sender as BaseControl
function ControlLog(sender, message) {
    if (sender === null || sender === undefined)
        throw new Error('LogTools: sender not set');

    console.log(sender.Name + '_' + sender.ID + ': ' + message);
}

export { Log as default, ControlLog }