import * as Types from '../actionTypes'

const initialState = {
    phones: ['iPhone 7 Plus', 'Samsung Galaxy A5']
};

export const reducerTestPage = (state = initialState, action) => {
    switch (action.type) {
        case Types.ADD_PHONE:
            return {
                ...state,
                phones: state.phones.concat([action.payload])
            };
        case Types.DELETE_PHONE:
            return {
                ...state,
                phones: state.phones.filter(item => item !== action.payload)
            };
        default:
            return state;
    }
};