import * as Types from '../actionTypes'

const initialState = {
    IsAdmin: false
};

export const reducerUser = (state = initialState, action) => {
    switch (action.type) {
        case Types.USER_ENTER:
            return { ...state, IsAdmin: true };
        case Types.USER_EXIT:
            return { ...state, IsAdmin: false };
        default:
            return state;
    }
};