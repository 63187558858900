import * as Types from './actionTypes'
import Queries from '../Tools/Queries'

/**
 * Создает статичный Action с указанными параметрами
 * @param {any} type Тип Action
 * @param {...any} argNames Массив хранимых и передаваемых аргументов
 */

function makeActionCreator(type, ...argNames) {
    return function(...args) {
        const action = { type };
        argNames.forEach((arg, index) => {
            action[argNames[index]] = args[index];
        });
        return action;
    };
}

/*Actions*/

/* equals to

export function addPhone(payload1, payload2) {
    return {
        type: Types.ADD_PHONE,
        payload1,
        payload2
    };
}
 */

/**Types.ADD_PHONE, payload*/
export const addPhone = makeActionCreator(Types.ADD_PHONE, 'payload');
/**Types.DELETE_PHONE, payload*/
export const deletePhone = makeActionCreator(Types.DELETE_PHONE, 'payload');
/**Types.USER_ENTER*/
export const userEnter = makeActionCreator(Types.USER_ENTER);
/**Types.USER_EXIT*/
export const userExit = makeActionCreator(Types.USER_EXIT);
/**Types.SET_ASSEMBLY_VERSION, payload*/
export const setAssemblyVersion = makeActionCreator(Types.SET_ASSEMBLY_VERSION, 'payload');


export function tryAdminConnect(command, isAdmin) {
    return async dispatch => {
        try {
            const result = await Queries.PostSpecialCommand(command);
            if (result) {
                if (!isAdmin)
                    dispatch(userEnter());
                else
                    dispatch(userExit());
            }
        }
        catch (e) {}
    };
}

export function invokeSetAssemblyVersion() {
    return async dispatch => {
        try {
            const result = await Queries.GetAssemblyVersion();
            dispatch(setAssemblyVersion(result));
        }
        catch (e) {}
    };
}