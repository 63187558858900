import Log from './LogTools'
import Notification from './Notification'


//Обертка для запросов к базе
//URL - 
//Methid : Get Post
//data: данные
export default function ApiQuery(url, method, data) {
    Log('ApiQuery', `query to ${url} with ${method}`);
    //let json = JSON.stringify(data);
    //debugger;
    return fetch(url,
            {
                method: method,
                headers: { 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
        .then(function(response) {
            return response.json();
        })
        .catch(function() {
            //Ошибка при парсинге response
            Notification.MesEr('Api query error parsing json', 'ApiQuery');
            throw new Error('');
        });
}

//Обертка для запросов к базе
//URL - 
//Methid : Get Post
//data: данные
export function ApiQueryNoResponse(url, method, data) {
    Log('ApiQuery', `query to ${url} with ${method}`);
    return fetch(url,
        {
            method: method,
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        });
}