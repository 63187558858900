import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './HomePage.css'

import ImageAnydesk from '../images/anydesk.png'
import ImageRadmin from '../images/Radmin.png'
import ImageRDP from '../images/rdp.png'

const DownloadPage = () => {

    const Downloads = [
        { Title: 'Скачать Anydesk', Image: ImageAnydesk, Alt: 'Anydesk', Name: 'anydesk.exe' },
        { Title: 'Скачать Radmin', Image: ImageRadmin, Alt: 'Radmin', Name: 'RadminViewer.rar' },
        { Title: 'Скачать RDP server', Image: ImageRDP, Alt: 'RDP', Name: 'server2019.rdp' }
    ];

    return (
        <Container>
            <Row>
                {
                    Downloads.map(download =>
                        <Col key={download.Name}>
                            <a href={`/downloadfile?name=${download.Name}`} target='_blank' rel='noopener noreferrer'>
                                <figure >
                                    <img src={download.Image} alt={download.Alt}/>
                                    <figcaption>{download.Title}</figcaption>
                                </figure>
                            </a>
                        </Col>)
                }
            </Row>
        </Container>
    );
};
export default DownloadPage