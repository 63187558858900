import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.min.css';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ChatComponent from './components/SignalR/Chat/ChatComponent';
import { invokeSetAssemblyVersion } from './Redux/actions';
import { SET_PLATFORM } from './Redux/actionTypes';
import { configureStore } from './Redux/configureStore';
import registerServiceWorker from './registerServiceWorker';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

const store = configureStore();
store.dispatch({ type: SET_PLATFORM });
store.dispatch(invokeSetAssemblyVersion());

render(
    <Provider store={store}>
        <BrowserRouter basename={baseUrl}>
            <App/>
            <ChatComponent/>
        </BrowserRouter>
    </Provider >,
    rootElement
);

registerServiceWorker();