import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tryAdminConnect } from '../Redux/actions.js';
import Configuration from '../Tools/Configuration'

const KeyListener = () => {
    const IsAdmin = useSelector(state => state.user.IsAdmin);
    const IsMobileDevice = useSelector(state => state.app.IsMobileDevice);
    const dispatch = useDispatch();
    const [command, setCommand] = useState('|');
    const [lastTime, setLastTime] = useState(Date.now());

    const keyPress = useCallback((e) => {
            if (IsMobileDevice)
                return;
            let keyCode;
            if (window.event) {
                keyCode = window.event.keyCode;
            }
            else if (e) {
                keyCode = e.keyCode;
            }
            const now = Date.now();
            //  если между нажатия прошло больше Х миллисекунд
            if (now - lastTime > Configuration.specialCommandDelay) {
                //  сбрасываем счетчик до нового значения
                if (keyCode !== Configuration.specialCommandBreak)
                    setCommand(`|${keyCode}|`);
                else
                    setCommand('|');
            }
            //  иначе
            else {
                //  если нажатая клавиша не Enter
                if (keyCode !== Configuration.specialCommandBreak) {
                    //  накапливаем счетчик
                    setCommand(prev => prev + `${keyCode}|`);
                }
                //  нажат Enter
                else {
                    const data = command;
                    setCommand('|');
                    //  если данные не пусты, отсылаем на бэк
                    if (data.replace('|', '').length > 0) {
                        dispatch(tryAdminConnect(data, IsAdmin));
                    }
                }
            }
            //  записываем время обращения
            setLastTime(Date.now());
        },
        [command, lastTime, IsMobileDevice, IsAdmin]);

    useEffect(() => {
            document.addEventListener('keydown', keyPress);

            return () => {
                document.removeEventListener('keydown', keyPress);
            };
        },
        [keyPress]);

    return (
        <div/>
    );
};
export default KeyListener