import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Navbar, NavItem } from 'reactstrap';

const Footer = () => {
    const AssemblyVersion = useSelector(state => state.app.AssemblyVersion);

    const footerNavItemStyle = {
        display: 'block',
        padding: '0.5rem 1rem'
    };

    return (
        <Navbar color='light' light className='navbar-expand-sm fixed-bottom border-top box-shadow mt-3'>
            <Container>
                <ul className='navbar-nav mr-auto'>
                    <NavItem style={footerNavItemStyle}>
                        &copy; Denhome
                    </NavItem>
                    <NavItem style={footerNavItemStyle}>
                        Версия сборки {AssemblyVersion}
                    </NavItem>
                    <NavItem style={footerNavItemStyle}>
                        Платформа .NET 5.0
                    </NavItem>
                </ul>
            </Container>
        </Navbar>
    );
};
export default Footer