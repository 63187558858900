import React, { useState, useMemo } from 'react';
import { Container, Row, Col, Collapse, Button, ListGroup, ListGroupItem, Badge, Spinner } from 'reactstrap';
import Queries from '../Tools/Queries'

import './HomePage.css'
import ImageWiki from '../images/wiki.png'
import ImageFile from '../images/file.png'
import ImageGit from '../images/git.png'
import ImageNuget from '../images/nuget.png'

const HomePage = () => {
    const [sitelist, setSitelist] = useState([]);
    const [loading, setLoading] = useState(true);
    const [collapsed, setCollapsed] = useState(false);


    const getSiteList = async () => {
        const data = await Queries.GetSiteList();
        setSitelist(data);
        setLoading(false);
    };

    const renderFetch = useMemo(() => {
            const badgeStyle = { margin: '0px 10px 0px 0px' };
            return (
                <ListGroup>
                    {
                        sitelist.map(
                            site =>
                            <ListGroupItem key={site.name} className='justify-content-between'>
                                <p style={{ margin: 0 }}>
                                    <strong>{site.name} <Badge pill>{site.bindings.length
                                    }</Badge> : </strong>
                                </p>
                                {
                                    site.bindings.map(
                                        binding =>
                                        <Badge href={binding.url} color='success' style={badgeStyle} key={binding.url}>{
                                            binding.url}</Badge>
                                    )
                                }
                            </ListGroupItem>
                        )
                    }

                </ListGroup>
            );
        },
        [sitelist]);

    const StaticSites = [
        { Title: 'Wiki', Image: ImageWiki, Alt: 'Wiki', URL: 'https://wiki.denhome.ru' },
        { Title: 'Файловый сервер', Image: ImageFile, Alt: 'File', URL: 'http://file.denhome.ru' },
        { Title: 'Git сервер', Image: ImageGit, Alt: 'Git', URL: 'https://git.denhome.ru/Repository/Index' },
        { Title: 'Nuget сервер', Image: ImageNuget, Alt: 'Nuget', URL: 'https://nuget.denhome.ru' }
    ];

    const onOpeningCollapse = () => {
        getSiteList();
    };

    return (
        <Container style={{ margin: '0px 0px 20px 0px' }}>
            <Row>
                {
                    StaticSites.map(site =>
                        <Col key={site.URL}>
                            <a href={site.URL}>
                                <figure>
                                    <img src={site.Image} alt={site.Alt}/>
                                    <figcaption>{site.Title}</figcaption>
                                </figure>
                            </a>
                        </Col>)
                }
            </Row>

            <Row xs='1' style={{ marginTop: '2rem' }}>
                <Button color='secondary' onClick={() => setCollapsed(prev => !prev)} style={{ marginBottom: '1rem' }}>
                    Список сайтов
                </Button>
                <Collapse isOpen={collapsed} onEntering={onOpeningCollapse}>
                    {
                        loading
                            ? <div>
                                  <Spinner color='secondary'/>
                                  <p>
                                      <em> Loading...</em>
                                  </p>
                              </div>
                            : renderFetch
                    }
                </Collapse>
            </Row>
        </Container>
    );
};
export default HomePage