import React from 'react';
import HostComponent from '../components/SignalR/HostPanel/HostComponent';

const AdminPage = () => {

    return (
        <div>
            <HostComponent />
        </div>
    );
};
export default AdminPage