import React, { useState, useEffect, useRef } from 'react';
import { Row, Card, Collapse, Button, Col } from 'reactstrap';
import Cookies from 'universal-cookie';
import { HubConnectionBuilder } from '@microsoft/signalr';

import ChatWindow from './Window';
import ChatInput from './Input';

const ChatComponent = () => {
    const [connection, setConnection] = useState(null);
    const [cookies] = useState(new Cookies());
    const [chat, setChat] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const latestChat = useRef(null);
    const jumbo = useRef();

    latestChat.current = chat;

    const scrollDown = () => {
        if (jumbo.current)
            jumbo.current.scrollTop = jumbo.current.scrollHeight;
    };

    useEffect(() => {
            const newConnection = new HubConnectionBuilder()
                .withUrl('/hubs/chat')
                .withAutomaticReconnect()
                .build();

            setConnection(newConnection);
            jumbo.current = document.getElementById('jumbo');
        },
        []);

    useEffect(() => {
            if (connection) {
                connection.start()
                    .then(result => {
                        connection.on('ReceiveMessage',
                            message => {
                                const updatedChat = [...latestChat.current];
                                updatedChat.push(message);

                                setChat(updatedChat);
                                scrollDown();
                            });

                        connection.on('ReceiveName',
                            name => {
                                cookies.set('chatName', name, { path: '/' });
                            });
                    })
                    .catch(e => console.log('Connection failed: ', e));
            }
            return () => {
                if (connection)
                    connection.stop();
            };
        },
        [connection, cookies]);

    const sendMessageAll = async (user, text) => {
        const chatMessage = {
            user: user,
            text: text
        };

        try {
            connection.invoke('SendMessageAll', chatMessage).catch(err => console.log(err));;
        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    };

    const chatPositionStyle = {
        position: 'fixed',
        zIndex: 999999,
        right: '12px',
        bottom: '50%'
    };

    const togglerStyle = {
        height: '336px',
        width: 26,
        padding: '0'
    };

    const buttonMessageStyle = {
        WebkitTransform: 'rotate(90deg)',
        margin: '00px 0 60px 0',
        padding: 0
    };

    const cardStyle = {
        padding: '0px 15px',
        minWidth: 300
    };

    return (
        <div style={chatPositionStyle}>
            <Row>
                <Col style={{ padding: '0px' }}>
                    <Button color='secondary' style={togglerStyle} onClick={() => setCollapsed(prev => !prev)}>
                        <p style={buttonMessageStyle}>Сообщения</p>
                    </Button>
                </Col>
                <Col style={{ padding: '0px 12px 0px 0px' }}>
                    <Collapse isOpen={collapsed} onEntering={() => scrollDown()}>
                        <Card style={cardStyle} body outline color='secondary'>
                            <Row>
                                <ChatWindow chat={chat}/>
                            </Row>
                            <Row>
                                <ChatInput sendMessageAll={sendMessageAll} cookies={cookies}/>
                            </Row>
                        </Card>
                    </Collapse>
                </Col>
            </Row>

        </div>
    );
};

export default ChatComponent;