import Log from './LogTools'
import Notification from './Notification'
import ApiQuery from './ApiQuery'

export default class Queries {

    static async GetSiteList() {
        return await ApiQuery('HomePage/GetSiteList', 'Get')
            .then(function(data) {
                //  данные в порядке
                if (data.successe) {
                    return data.items;
                }
                //  ошибка на бэкенде
                else {
                    Notification.MesEr(data.resMessage, data.actionName);
                    Log('Queries', `Action: ${data.actionName} | Message: ${data.resMessage}`);
                    return [];
                }
            });
    }

    static async PostSpecialCommand(command) {
        return await ApiQuery('AdminPage/CanOpen', 'Post', command)
            .then(function(data) {
                //  данные в порядке
                if (data.successe) {
                    return data.canOpen;
                }
                //  ошибка на бэкенде
                else {
                    Notification.MesEr(data.resMessage, data.actionName);
                    Log('Queries', `Action: ${data.actionName} | Message: ${data.resMessage}`);
                    return false;
                }
            });
    }

    static async GetHostList() {
        return await ApiQuery('AdminPage/GetHostList', 'Get')
            .then(function(data) {
                //  данные в порядке
                if (data.successe) {
                    return data.items;
                }
                //  ошибка на бэкенде
                else {
                    Notification.MesEr(data.resMessage, data.actionName);
                    Log('Queries', `Action: ${data.actionName} | Message: ${data.resMessage}`);
                    return false;
                }
            });
    }

    static async GetAssemblyVersion() {
        return await ApiQuery('HomePage/GetAssemblyVersion', 'Post')
            .then(function (data) {
                return data;
            });
    }

}