import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

import Configuration from '../Tools/Configuration';
import { tryAdminConnect } from '../Redux/actions.js';
import ImageHome from '../images/home.png'

const NavMenu = () => {
    const IsAdmin = useSelector(state => state.user.IsAdmin);
    const IsMobileDevice = useSelector(state => state.app.IsMobileDevice);
    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(true);
    const [inputValue, setInputValue] = useState('');

    const keyDownInput = useCallback((e) => {
        //  Если нажат Enter
        if (e.keyCode === Configuration.specialCommandBreak) {
                const data = inputValue.toUpperCase().split('').map(c => c.codePointAt(0)).join('|');
                setInputValue('');
                if (data.replace('|', '').length > 0) {
                    dispatch(tryAdminConnect(data, IsAdmin));
                }
            }
        },
        [inputValue, IsAdmin]);

    const figureStyle = {
        display: 'flex',
        width: '220px',
        height: '48px',
        float: 'left',
        margin: '0px',
        alignItems: 'center'
    };

    const imgStyle = {
        width: '48px',
        height: '48px',
        margin: '0px 10px 0px 0px'
    };

    return (
        <header>
            <Navbar color='dark' className='navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3' dark>
                <Container>
                    <NavbarBrand tag={Link} to='/'>

                        <figure style={figureStyle}>
                            <img src={ImageHome} alt='Home' style={imgStyle}/>
                            <figcaption className='text-light'>Denhome Сервер</figcaption>
                        </figure>

                    </NavbarBrand>

                    <NavbarToggler onClick={() => setCollapsed(prev => !prev)} className='mr-2'/>

                    <Collapse className='d-sm-inline-flex' isOpen={!collapsed} navbar>

                        <ul className='navbar-nav mr-auto'>
                            <NavItem>
                                <NavLink tag={Link} className='text-light' to='/download'>Загрузки</NavLink>
                            </NavItem>
                            {
                                IsAdmin
                                    ? <NavItem>
                                          <NavLink tag={Link} className='text-light' to='/admin'>Администрирование</NavLink>
                                      </NavItem>
                                    : null
                            }
                        </ul>

                        {
                            IsMobileDevice
                                ? <ul className='navbar-nav'>
                                    <NavItem>
                                        <Input value={inputValue} onChange={e => setInputValue(e.target.value)} onKeyDown={keyDownInput} />
                                      </NavItem>
                                  </ul>
                                : null
                        }

                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
};
export default NavMenu