import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const ChatInput = (props) => {
    const [user, setUser] = useState(() => {
        const coockieName = props.cookies.get('chatName');
        return coockieName ? coockieName : '';
    });
    const [message, setMessage] = useState('');

    const [userValid, setUserValid] = useState(true);
    const [messageValid, setMessageValid] = useState(true);

    useEffect(() => {
            const coockieName = props.cookies.get('chatName');
            setUser(coockieName ? coockieName : '');
        },
        [props.cookies]);

    const onSubmit = (e) => {
        e.preventDefault();
        const isUserProvided = user && user !== '';
        const isMessageProvided = message && message !== '';

        setUserValid(isUserProvided);
        setMessageValid(isMessageProvided);

        if (isUserProvided && isMessageProvided) {
            props.sendMessageAll(user, message);
            setMessage('');
        }
    };

    const onChangeUser = (e) => {
        setUser(e.target.value);
        props.cookies.set('chatName', e.target.value, { path: '/' });
        setUserValid(e.target.value && e.target.value !== '');
    }

    const onChangeMessage = (e) => {
        setMessage(e.target.value);
        setMessageValid(e.target.value && e.target.value !== '');
    }

    return (
        <Form style={{ width: '100%' }}>
            <FormGroup className='mb-0'>
                <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                        <InputGroupText>Имя</InputGroupText>
                    </InputGroupAddon>
                    <Input
                        value={user}
                        onChange={onChangeUser}
                        invalid={!userValid} />
                </InputGroup>
            </FormGroup>
            <InputGroup>
                <Input
                    type='textarea'
                    style={{ resize: 'none' }}
                    value={message}
                    onChange={onChangeMessage}
                    invalid={!messageValid} />
                <InputGroupAddon addonType='append'>
                    <Button style={{ padding:0 }} onClick={onSubmit}>Отправить</Button>
                </InputGroupAddon>
            </InputGroup>
        </Form>
    );
};

export default ChatInput;