import React from 'react';
import { Route, Redirect } from 'react-router';
import { useSelector } from 'react-redux';
import { Container } from 'reactstrap';

import HomePage from './Pages/HomePage';
import AdminPage from './Pages/AdminPage';
import DownloadPage from './Pages/DownloadPage';
import NavMenu from './components/NavMenu';
import Footer from './components/Footer';
import KeyListener from './components/KeyListener';

import './custom.css'

const App = () => {
    const IsAdmin = useSelector(state => state.user.IsAdmin);

    return (
        <div>
            <KeyListener/>
            <NavMenu/>
            <Container>
                <Route exact path='/' component={HomePage}/>
                <Route path='/download' component={DownloadPage}/>
                {
                    IsAdmin
                        ? <Route path='/admin' component={AdminPage}/>
                        : <Redirect from='/admin' to='/'/>
                }
            </Container>
            <Footer/>
        </div>
    );
};
export default App